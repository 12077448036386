
import { user, will, charities } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillCharityConsentIndex',
  mixins: [user, will, charities],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    return {
      isDirty: false,
      prevRoute: null,
      selectedValue: null,
    };
  },
  computed: {
    isComplete() {
      return this.selectedValue !== null;
    },
    showOptions() {
      return !this.isDirty || this.willMeta.notify_charities === true;
    },
    showReset() {
      return this.isDirty && this.willMeta.notify_charities === false;
    },
    nextModule() {
      return this.$route.query['next-module'];
    },
    submitLabel() {
      if (this.nextModule === 'gifts')
        return this.$t('forms.labels.continueToGifts');
      if (this.nextModule === 'assets')
        return this.$t('forms.labels.continueToAssets');
      return this.$t('forms.labels.next');
    },
    nextPath() {
      if (this.nextModule === 'gifts') return '/will/gifts';
      if (this.nextModule === 'assets') return '/will/assets';
      return '/will';
    },
    buttonContent() {
      return [
        {
          id: 'charity-consent-yes',
          value: true,
          header: this.$t('forms.labels.yes'),
          content: this.$t('pages.will.charity.maximiseImpactGift', {
            charity: this.charityText,
          }),
        },
        {
          id: 'charity-consent-no',
          value: false,
          header: this.$t('forms.labels.no'),
          content: this.$t('pages.will.charity.dontWantPlan', {
            charity: this.charityText,
          }),
        },
      ];
    },
    charityText() {
      return this.$tc(
        'texts.words.charity',
        (this.charities && this.charities.length) || 2
      );
    },
    modelValue: {
      get() {
        return this.selectedValue;
      },
      set(newValue) {
        this.selectedValue = newValue;
        this.isDirty = true;
      },
    },
  },
  mounted() {
    if (this.willMeta.notify_charities != null) {
      this.selectedValue = this.willMeta.notify_charities;
    } else {
      this.selectedValue = true;
    }
    this.$nuxt.$emit('sendTrackingEvent', {
      event: 'confirmation_prompt',
      props: {
        topic: 'Share contact details with charities',
      },
    });
  },
  methods: {
    reset() {
      this.isDirty = false;
      this.willMeta.notify_charities = null;
    },
    async submit() {
      this.willMeta.notify_charities = this.selectedValue;
      this.$nuxt.$emit('sendTrackingEvent', {
        event: this.willMeta.notify_charities
          ? 'accept_prompt'
          : 'reject_prompt',
        props: {
          topic: 'Share contact details with charities',
        },
      });

      await this.updateWillMeta();
      this.$router.push({
        path: this.nextPath,
      });
    },
  },
};
